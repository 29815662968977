.row-margin {
  margin: 0px;
}

.footer-wrapper {
  height: 80vh !important;
  background-color: #171f36 !important;
  margin: 0px !important;
  padding-top: 25px;
}

.footer-line {
  height: 2px !important;
  background-color: #fff !important;
  margin-bottom: 10%;
  margin-left: 30px;
  margin-right: 30px;
}

.footer-logo {
  max-height: 80px;
  margin-left: 5rem;
  margin-bottom: 2rem;
}

.footer-link-row {
  margin-left: 6rem;
}

.footer-link-row > a {
  color: #fff;
  text-decoration: underline;
}

.footer-copyright-row {
  margin-left: 6rem;
  margin-top: 5rem;
}

.footer-copyright {
  color: #fff;
}
