.error-notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #fa8231;
  border-bottom: 3px solid #fa8231;
  background: #c0392b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
.error-notification {
  padding: 0;
  color: #fff;
}
.error-notification .material-icons {
  color: #fa8231;
  font-size: 3rem;
  vertical-align: middle;
}
