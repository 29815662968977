.notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #b8e994;
  border-bottom: 3px solid #b8e994;
  background: #27ae60;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notification {
  padding: 0;
  color: #b8e994;
}
.notification .material-icons {
  color: #44bd32;
  font-size: 3rem;
  vertical-align: middle;
}
