.hero-container {
  background-image: linear-gradient(to right, #171f36, transparent),
    url(../../../assets//img/hero-img.jpeg);
  background-size: cover;
  background-position: 68.1818% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
  display: flex;
  justify-content: flex-start;
  padding-right: 2.5vw;
  padding-left: 7vw;
  margin: 0 auto;
}

.hero-text-container {
  width: 60%;
}

.hero-text-container > h1 {
  white-space: pre-wrap;
  color: #fff;
  font-size: calc(3.2vw + 1rem);
  line-height: 1.232;
  font-weight: bold;
}

.hero-text-container > h4 {
  margin: 2rem 0;
  line-height: 1.3664;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 1483px) and (min-width: 0) and (orientation: landscape) {
  .hero-text-container > h4 {
    font-size: calc(0.72vw + 1rem);
  }
}

.hero-text-container > h3 {
  margin: 2rem 0;
  line-height: 1.3328;
  color: #fff;
  font-weight: bolder;
}

.hero-text-container > h3 > a {
  color: #fff;
}

@media screen and (max-width: 1483px) and (min-width: 0) and (orientation: landscape) {
  h3 {
    font-size: calc(1.44vw + 1rem);
  }
}

.page-section {
  align-items: center;
  min-height: 40vh;
  color: #000;
  background-color: #fff;
  position: relative;
  display: flex;
  box-sizing: border-box;
}

.section-background {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.section-wrapper {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  padding-left: 100px;
  padding-right: 100px;
  justify-content: center;
}

.section-wrapper > h3 {
  text-align: center;
  font-weight: 500;
}

@media screen and (max-width: 1483px) and (min-width: 0) and (orientation: landscape) {
  .section-wrapper > h3 {
    font-size: calc(1vw + 1rem);
  }
}

.page-section-two {
  background-image: url(../../../assets//img/sectionImg.jpeg);
  background-position: 65.7343% 61.5186%;
  background-size: cover;
  align-items: center;
  min-height: 66vh;
  color: #fff;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
}

.hero-link {
  text-decoration: underline;
}
