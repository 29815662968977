.our-offerings-wrapper {
  background-color: #171f36;
  min-height: 66vh;
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
  justify-content: center;
  padding-right: 3vw;
  padding-left: 3vw;
  margin: 0 auto;
  box-sizing: content-box;
  display: flex;
}

.our-offerings-content {
  width: 75%;
}

.our-offerings-row {
  color: #fff;
  text-align: center;
  margin: 0px !important;
}

.our-offerings-col-heading {
  padding-bottom: 60px;
}

.learn-more-btn-row {
  padding-top: 60px;
}

.our-offerings-row-two {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  text-align: center;
  margin: 0px;
}

#our-offerings-row-two-h1 {
  color: #0c2d85;
  padding-bottom: 40px;
}
