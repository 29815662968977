:root {
  --primary: #0c2d85;
}

.btn {
  padding: 8px 20px;
  border-radius: 0.4rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.btn--primary:hover {
  text-decoration: none;
  color: #fff;
  opacity: 0.8;
}

.btn--background-white {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
}

.btn--background-white:hover {
  opacity: 0.8;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 16px;
  font-weight: bold;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}
