.contact-us-div-one {
  height: 20vh;
  background-color: #eaedf6;
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
  padding-right: 3vw;
  padding-left: 3vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.contact-us-div-one > h1 {
  color: #0c2d85;
  text-align: center;
  font-size: 66px;
  font-weight: bolder;
}

.contact-us-form-div {
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;
  justify-content: center;
  padding-right: 15vw;
  padding-left: 15vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.center {
  text-align: center !important;
}

.login-button {
  background: #0c2d85;
  border-radius: 10px;
}

.white-text {
  color: #fff;
}

.error {
  color: #f00 !important;
  font-size: 11px !important;
}
