/*  SOCIAL ICONS  */

.social {
  position: fixed;
  z-index: 5;
  top: 50%;
  margin: 0;
  transform: translate(-15%, -50%);
  background: rgba(0, 0, 0, 0);
}
.social-list {
  list-style: none;
  background: transparent;
  padding: 15%;
}

.social-list > li {
  margin-bottom: 10% !important;
}

.fa {
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  transition: transform;
  transition-duration: 1s;
  margin: 1px 0;
}
.fa-facebook,
.fa-linkedin {
  background: #0054f3;
}
.fa-facebook {
  padding-right: 13px;
  padding-left: 13px;
}
.fa-pinterest,
.fa-linkedin {
  padding-right: 11px;
  padding-left: 11px;
}
.fa:hover {
  transform: rotate(380deg);
  color: #fff;
}
.fa-pinterest,
.fa-youtube-play,
.fa-twitter {
  background: #bf2037;
}
