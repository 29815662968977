.default-setting {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  padding-right: 7vw;
  padding-left: 7vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.about-us-img-row {
  padding: 2rem 0;
}

.about-us-content-div {
  padding-right: 3rem;
}

.about-us-content-div > h3 {
  margin-bottom: 3rem;
}

.header-div {
  text-align: center;
  padding-bottom: 8vmax;
}

.header-div > h2 {
  color: #0c2d85;
  font-size: 3rem;
}

.header-description-div > h4 {
  color: #000000;
  font-size: 1.3rem;
}

.our-values-col {
  margin-bottom: 2rem;
}

.our-values-col > h3 {
  color: #000000;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.our-values-col > p {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.our-team-div {
  padding: 3rem 0;
}

.our-team-div > h3 {
  margin-bottom: 1rem;
}

.about-us-img {
  width: 100%;
  height: 100%;
}

.about-us-row-div {
  padding: 1rem 15px;
}

.about-us-row-line {
  height: 2px;
  margin: 0px 15px;
  background-color: #000000;
  width: 100%;
}

.pradeep-row-line {
  margin-top: 2rem;
}

/* css for offerings */

.offerings.img {
  width: 50%;
  height: 50%;
}

.offering-right-img {
  margin-left: 13%;
}

.offerings-sub-img {
  width: 55%;
  height: 55%;
  margin-left: 20%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.offerings-row-line {
  margin: 4rem 0.9rem;
}

.promise-div {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.promise-div > h3 {
  margin-bottom: 2rem;
}

.promise-div > p {
  font-size: 1.5rem;
}

.offering-img-col {
  max-width: 37%;
}

.offering-content-col {
  max-width: 63%;
}

.offering-content-col > p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}
