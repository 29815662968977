.default-setting {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  padding-right: 7vw;
  padding-left: 7vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.header-div {
  text-align: center;
  padding-bottom: 8vmax;
}

.about-us-img-row {
  padding: 2rem 0;
}

.about-us-content-div {
  padding-right: 3rem;
}

.about-us-content-div > h1 {
  color: #0c2d85;
  margin-bottom: 2rem;
}

.about-us-content-div > span {
  display: inline-block;
  margin-bottom: 2rem;
}
