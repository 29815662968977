.default-setting {
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
  padding-right: 7vw;
  padding-left: 7vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.header-div {
  text-align: center;
  padding-bottom: 8vmax;
}

.community-img-div {
  text-align: center;
}
